import styled from "styled-components";

export const Expired = () => {
    return (
        <SectionBanner>
            <Header2Title>
                Este link está expirado, por favor fale novamente com o vendedor
                e peça um novo link.
            </Header2Title>
        </SectionBanner>
    );
};

const SectionBanner = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: url(https://images.pexels.com/photos/66997/pexels-photo-66997.jpeg?cs=srgb&dl=nature-sky-sunset-the-mountains-66997.jpg&fm=jpg);
    background-size: cover;
    background-position: center; */
`;

const Header2Title = styled.h2`
    color: #fefefe;
    font-size: 32px;
    letter-spacing: 4px;
`;
