import { useRouteError } from "react-router-dom";
import styled from "styled-components";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <SectionBanner>
            <Header2Title>
                Oops! Desculpe, ocorreu um erro, tente novamente em alguns
                minutos.
            </Header2Title>
        </SectionBanner>
    );
}

const SectionBanner = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: url(https://images.pexels.com/photos/66997/pexels-photo-66997.jpeg?cs=srgb&dl=nature-sky-sunset-the-mountains-66997.jpg&fm=jpg);
    background-size: cover;
    background-position: center; */
`;

const Header2Title = styled.h2`
    color: #fefefe;
    font-size: 32px;
    letter-spacing: 4px;
`;
