import React from "react";
import ReactDOM from "react-dom/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ErrorPage from "./error-page";
import { Home } from "./pages/home";
import { Exit } from "./pages/exit";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Expired } from "./pages/expired";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/:clientUuid",
        element: <ValidateUuid />,
        errorElement: <ErrorPage />,
    },
]);

function ValidateUuid() {
    let params = useParams();
    let clientUuid = params.clientUuid.match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    );
    if (!clientUuid) {
        return <Expired />;
    }
    return <Exit clientUuid={params.clientUuid} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
